import { render, staticRenderFns } from "./Jibenmian.vue?vue&type=template&id=65933ac0&scoped=true&"
import script from "./Jibenmian.vue?vue&type=script&lang=js&"
export * from "./Jibenmian.vue?vue&type=script&lang=js&"
import style0 from "./Jibenmian.vue?vue&type=style&index=0&id=65933ac0&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/_vue-loader@15.9.8@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "65933ac0",
  null
  
)

export default component.exports