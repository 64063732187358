<template>
  <div>
    <el-table class="table-1" :data="table1" stripe>
      <el-table-column
        align="center"
        header-align="center"
        label="联赛积分排名"
      >
        <el-table-column
          align="center"
          header-align="center"
          :label="info.zteam"
        >
          <el-table-column
            align="center"
            header-align="center"
            prop="z_quanchang"
            label="全场"
            width="64"
          ></el-table-column>
          <el-table-column
            align="center"
            header-align="center"
            prop="z_lunci"
            label="赛"
            width="40"
          ></el-table-column>
          <el-table-column
            align="center"
            header-align="center"
            prop="z_win"
            width="40"
            label="胜"
          ></el-table-column>
          <el-table-column
            align="center"
            header-align="center"
            prop="z_ping"
            width="40"
            label="平"
          ></el-table-column>
          <el-table-column
            align="center"
            header-align="center"
            prop="z_fu"
            width="40"
            label="负"
          ></el-table-column>
          <el-table-column
            align="center"
            header-align="center"
            prop="z_jinqiu"
            width="40"
            label="得"
          ></el-table-column>
          <el-table-column
            align="center"
            header-align="center"
            prop="z_diuqiu"
            width="40"
            label="失"
          ></el-table-column>
          <el-table-column
            align="center"
            header-align="center"
            prop="z_jifen"
            width="55"
            label="积分"
          ></el-table-column>
          <el-table-column
            align="center"
            header-align="center"
            prop="z_paiming"
            width="55"
            label="排名"
          ></el-table-column>
          <el-table-column
            align="center"
            header-align="center"
            prop="z_per"
            width="55"
            label="胜率"
          ></el-table-column>
        </el-table-column>
        <el-table-column
          align="center"
          header-align="center"
          :label="info.kteam"
        >
          <el-table-column
            align="center"
            header-align="center"
            prop="k_quanchang"
            label="全场"
            width="64"
          ></el-table-column>
          <el-table-column
            align="center"
            header-align="center"
            prop="k_lunci"
            label="赛"
            width="40"
          ></el-table-column>
          <el-table-column
            align="center"
            header-align="center"
            prop="k_win"
            width="40"
            label="胜"
          ></el-table-column>
          <el-table-column
            align="center"
            header-align="center"
            prop="k_ping"
            width="40"
            label="平"
          ></el-table-column>
          <el-table-column
            align="center"
            header-align="center"
            prop="k_fu"
            width="40"
            label="负"
          ></el-table-column>
          <el-table-column
            align="center"
            header-align="center"
            prop="k_jinqiu"
            width="40"
            label="得"
          ></el-table-column>
          <el-table-column
            align="center"
            header-align="center"
            prop="k_diuqiu"
            width="40"
            label="失"
          ></el-table-column>
          <el-table-column
            align="center"
            header-align="center"
            prop="k_jifen"
            width="55"
            label="积分"
          ></el-table-column>
          <el-table-column
            align="center"
            header-align="center"
            prop="k_paiming"
            width="55"
            label="排名"
          ></el-table-column>
          <el-table-column
            align="center"
            header-align="center"
            prop="k_per"
            width="55"
            label="胜率"
          ></el-table-column>
        </el-table-column>
      </el-table-column>
    </el-table>
    <el-table class="table-2" :data="table2" stripe>
      <el-table-column align="center" header-align="center" label="历史对阵">
        <el-table-column
          align="center"
          header-align="center"
          prop="league"
          label="联赛"
          width="100"
        ></el-table-column>
        <el-table-column
          align="center"
          header-align="center"
          prop="date"
          label="日期"
          width="133"
        ></el-table-column>
        <el-table-column
          align="center"
          header-align="center"
          prop="zteam"
          label="主场"
          width="133"
        ></el-table-column>
        <el-table-column
          align="center"
          header-align="center"
          prop="bifen"
          label="比分"
          width="100"
        ></el-table-column>
        <el-table-column
          align="center"
          header-align="center"
          prop="kteam"
          label="客场"
          width="133"
        ></el-table-column>
        <el-table-column
          align="center"
          header-align="center"
          prop="asia"
          label="亚盘"
        ></el-table-column>
        <el-table-column
          align="center"
          header-align="center"
          prop="dx_asia"
          label="大小盘"
        ></el-table-column>
      </el-table-column>
    </el-table>
    <el-table class="table-3" :data="table3" stripe :cell-style="cellStyle">
      <el-table-column align="center" header-align="center" label="近期战绩">
        <el-table-column
          align="center"
          header-align="center"
          prop="league"
          label="联赛"
          width="100"
        ></el-table-column>
        <el-table-column
          align="center"
          header-align="center"
          prop="date"
          label="日期"
          width="133"
        ></el-table-column>
        <el-table-column
          align="center"
          header-align="center"
          prop="zteam"
          label="主场"
          width="133"
        ></el-table-column>
        <el-table-column
          align="center"
          header-align="center"
          prop="bifen"
          label="比分"
          width="100"
        ></el-table-column>
        <el-table-column
          align="center"
          header-align="center"
          prop="kteam"
          label="客场"
          width="133"
        ></el-table-column>
        <el-table-column
          align="center"
          header-align="center"
          prop="asia"
          label="亚盘"
        ></el-table-column>
        <el-table-column
          align="center"
          header-align="center"
          prop="dx_asia"
          label="大小盘"
        ></el-table-column>
      </el-table-column>
    </el-table>
    <el-table
      class="table-4"
      :data="table4"
      stripe
      border
      :cell-style="cellStyle"
    >
      <el-table-column
        align="center"
        header-align="center"
        prop="league"
        label="联赛"
        width="100"
      ></el-table-column>
      <el-table-column
        align="center"
        header-align="center"
        prop="date"
        label="日期"
        width="133"
      ></el-table-column>
      <el-table-column
        align="center"
        header-align="center"
        prop="zteam"
        label="主场"
        width="133"
      ></el-table-column>
      <el-table-column
        align="center"
        header-align="center"
        prop="bifen"
        label="比分"
        width="100"
      ></el-table-column>
      <el-table-column
        align="center"
        header-align="center"
        prop="kteam"
        label="客场"
        width="133"
      ></el-table-column>
      <el-table-column
        align="center"
        header-align="center"
        prop="asia"
        label="亚盘"
      ></el-table-column>
      <el-table-column
        align="center"
        header-align="center"
        prop="dx_asia"
        label="大小盘"
      ></el-table-column>
    </el-table>
  </div>
</template>

<script>
import {
  getShowBaseHistoryById,
  getShowBaseScoreById
} from "@/api/fenxi.js";

export default {
  data() {
    return {
      id: 0,
      info: {
        zteam: "",
        kteam: ""
      },
      table1: [],
      table2: [],
      table3: [],
      table4: []
    };
  },
  mounted() {
    this.id = this.$route.query.id;
    this.getShowBaseScoreById();
    this.getShowBaseHistoryById();
  },
  methods: {
    getShowBaseScoreById() {
      getShowBaseScoreById(this.id).then(res => {
        
        this.info = res.data[0] || [];
        let map = {
          0:'总',
          1:'主场',
          2:'客场',
          3:'近6场'
        }
        this.table1 = res.data.map((item,index)=>{
          item.z_quanchang = map[index]
          item.k_quanchang = map[index]
          return item
        })
      });
    },
    cellStyle({ row, column, rowIndex, columnIndex }) {
      if (
        (row.zteam == this.info.zteam && column.label === "主场") ||
        (row.kteam == this.info.zteam && column.label === "客场") ||
        (row.zteam == this.info.kteam && column.label === "主场") ||
        (row.kteam == this.info.kteam && column.label === "客场")
      ) {
        console.log(row, column, rowIndex, columnIndex);
        return { color: "#43B5D8" };
      }
    },
    getShowBaseHistoryById() {
      getShowBaseHistoryById(this.id).then(res => {
        this.table2 = res.data.filter(item=>item.type === '历史对阵');
        this.table3 = res.data.filter(item=>item.type === '主队最近');
        this.table4 = res.data.filter(item=>item.type === '客队最近');
      });
    },
  }
};
</script>

<style lang="scss" scoped>
/deep/.el-table thead.is-group th {
  background: #16a085;
}
/deep/.table-1 {
  thead {
    tr:nth-of-type(2) {
      th:nth-of-type(1) {
        background: #fdefd2;
        color: #000;
      }
      th:nth-of-type(2) {
        background: #edf6f4;
        color: #000;
      }
    }
    tr:nth-of-type(3) {
      th {
        background: #fdefd2;
        color: #000;
      }
      th:nth-of-type(n + 11) {
        background: #edf6f4;
        color: #000;
      }
    }
  }
}
/deep/.table-2 {
  thead {
    tr:nth-of-type(2) {
      th {
        background: #edf6f4;
        color: #000;
      }
    }
  }
}
/deep/.table-3 {
  thead {
    tr:nth-of-type(2) {
      th {
        background: #edf6f4;
        color: #000;
      }
    }
  }
}
/deep/.table-4 {
  thead {
    tr:nth-of-type(1) {
      th {
        background: #edf6f4;
        color: #000;
      }
    }
  }
}

.title {
  height: 28px;
  font-size: 20px;
  font-weight: bold;
  line-height: 28px;
  color: #16a085;
}
.el-divider {
  margin-top: 5px;
  margin-bottom: 15px;
}
.simply {
  background: #f6f7f8;
  border-radius: 6px;
  padding: 15px;

  font-size: 14px;
  font-weight: bold;
  line-height: 26px;
  color: #333333;
}
</style>
